//
// toasts.scss
//

body[data-layout-mode='dark'] {
  .toast,
  .toast-header {
    background-color: rgba($gray-dark-300, 0.85);
    color: $gray-dark-400;
  }

  .toast-header {
    color: $gray-dark-500;
  }
}
